import React, {useState} from "react";
import RenderContent from "../../RenderContent";
import { ImagePass } from "../../Image/ImagePass";
import { Container } from "../../Container";
import "./StaffProfiles.scss";
import Modal from 'react-modal';

export const StaffProfiles = ({ staffProfiles, title }) => {
  return (
    <Container>
      <section className="staff-profiles layout">
        <div className="staff-profiles__wrapper">
          {title && <h2 className="heading">{title}</h2>}
          <div className="profiles">
            {Object.keys(staffProfiles).length > 0 &&
              staffProfiles?.map((profile, index) => {
                return (
                  <Person profile={profile} key={index} />
                );
              })}
          </div>
        </div>
      </section>
    </Container>
  );
};



const Person = ({profile}) => {
  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  return (
    <>
      {profile.introduction && (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Person Modal"
          className="staff-modal"
          closeTimeoutMS={200}
        >
          <div className="modal">
            <button className="modal-btn" onClick={closeModal}><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" clip-rule="evenodd" d="M8 6.6087L14.6087 0L16 1.3913L9.3913 8L16 14.6087L14.6087 16L8 9.3913L1.3913 16L0 14.6087L6.6087 8L0 1.3913L1.3913 0L8 6.6087Z" fill="#272932"></path> </svg></button>
            <div className="modal-content">
              <div className="image">
                <div className="aspect">
                  <ImagePass src={profile.image} />
                </div>
              </div>
              <div className="content">
                <p className="h3">{profile.name}</p>
                <RenderContent content={profile.position} />
                <RenderContent content={profile.introduction} />
              </div>
              </div>
            </div>
        </Modal>)}
      <div className={`profile ${profile.introduction ? "profile--modal" : ""}`} onClick={()=> profile.introduction ? openModal() : null}>
        <div className="image">
          <div className="aspect">
            <div className="anim">
              <ImagePass src={profile.image} />
            </div>
          </div>
        </div>
        <div className="content">
          <h3 className="name">{profile.name}</h3>
          <RenderContent content={profile.position} />
        </div>
      </div>
    </>
  )
}