import React from "react";
import "./JobBoard.scss";

export const JobBoard = ({ url }) => {
  return (
    <section className="layout job-board">
      <div className="job-board__wrapper">
        <iframe
          id="elmo-recruitment-embed"
          style={{ width: "100%", overflow: "hidden", border: 0 }}
          src={url}
          height="800"
          allowFullScreen={true}
          mozallowfullscreen="true"
          msallowfullscreen="true"
          webkitallowfullscreen="true"
          allow="autoplay; fullscreen *; geolocation; microphone; camera; midi; monetization; xr-spatial-tracking; gamepad; gyroscope; accelerometer; xr; cross-origin-isolated"
        ></iframe>
      </div>
    </section>
  );
};
